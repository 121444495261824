<template>
  <!-- Card Banner mit Category Title -->
  <q-card class="my-card" @dragstart="handleDragStart($event, subject)">
    <q-tooltip v-if="subject.status == 'unavailable'">
      {{ $t("studyplan.subject_requirement") }}
    </q-tooltip>
    <q-card-section
      @click="showPath(subject, $event)"
      class="banner column justify-end items-start q-pa-sm cursor-pointer"
      :style="{
        backgroundColor: getSubjectColor(),
        backgroundImage: getSubjectActiveColor(),
      }"
    >
      <q-badge
        text-color="grey-7"
        class="absolute-top-right q-pa-sm"
        color="transparent"
        align="top"
      >
        {{ subject.category }}
      </q-badge>
      <div :class="fontSize">
        {{ subject.name }}
        <span v-if="checkSubjectName()">
          <q-icon name="info" color="warning" />
          <q-tooltip>
            {{ getSubjectNameTooltip() }}
          </q-tooltip>
        </span>
      </div>
      <q-linear-progress
        v-if="showProgressBarSBWL1(subject._id)"
        size="20px"
        :value="calculateProgressSbwl(0)"
        color="blue-4"
        class="q-mt-sm"
      />
      <q-linear-progress
        v-if="showProgressBarSBWL2(subject._id)"
        size="20px"
        :value="calculateProgressSbwl(1)"
        color="blue-4"
        class="q-mt-sm"
      />
      <q-linear-progress
        v-if="showProgressBarSBWL3(subject._id)"
        size="20px"
        :value="calculateProgressSbwl(2)"
        color="blue-4"
        class="q-mt-sm"
      />
      <q-linear-progress
        v-if="
          subject.category == 'Freies Wahlfach' ||
          subject.category == 'Free Electives and Internship'
        "
        size="20px"
        :value="calculateProgressFreeElectives()"
        :max="2"
        color="blue-4"
        class="q-mt-sm"
      />
    </q-card-section>

    <q-separator />
    <!-- Card lower part Info -->
    <q-card-section class="items">
      <!-- Course Info -->
      <div class="row items-end">
        <div class="col-8">
          <div class="row justify-start no-wrap">
            <div
              :class="itemSize + ' col-3'"
              :style="{
                backgroundColor: getSubjectColor(),
                pointerEvents: 'none',
              }"
            >
              <p class="upper-text">{{ subject.subject_type }}</p>
              <p class="lower-text">{{ $t("studyplan.subject_type") }}</p>
            </div>
            <div
              :class="itemSize + ' col-3'"
              :style="{
                backgroundColor: getSubjectColor(),
                pointerEvents: 'none',
              }"
            >
              <p class="upper-text">{{ subject.ects }}</p>
              <p class="lower-text">ECTS</p>
            </div>
            <!-- SST wird gehided -->
            <div
              style="display: none"
              :class="itemSize + ' col-3'"
              :style="{
                backgroundColor: getSubjectColor(),
                pointerEvents: 'none',
              }"
            >
              <p class="upper-text">{{ subject.ch }}</p>
              <p class="lower-text">SSt</p>
            </div>
            <div
              v-if="
                subject.category !== 'Spezielle Betriebswirtschaftslehre' &&
                subject.category !== 'Specializations' &&
                subject.category !== 'Freies Wahlfach' &&
                subject.category !== 'Free Electives and Internship' &&
                subject.name !== 'Introduction to Academic Writing'
              "
              :class="itemSize + ' col-3'"
              :style="{ backgroundColor: getSubjectColor(), cursor: 'pointer' }"
            >
              <q-tooltip v-if="subject.status == 'can-do'">
                {{ $t("studyplan.subject_grade_requirement") }}
              </q-tooltip>
              <q-tooltip v-else-if="subject.status == 'done'">
                {{ $t("studyplan.subject_grade_info") }}
              </q-tooltip>
              <p class="upper-text">
                {{ subject.grade || $t("studyplan.subject_grade") }}
              </p>
              <p v-if="subject.grade && subject.grade > 0" class="lower-text">
                {{ $t("studyplan.subject_grade") }}
              </p>

              <q-popup-edit
                v-if="subject.status == 'done'"
                v-model="grade"
                class="text-grey-9"
                transition-show="flip-up"
                transition-hide="flip-down"
                v-slot="scope"
              >
                <q-input
                  :hint="$t('studyplan.subject_grade_info2')"
                  color="grey-9"
                  type="number"
                  v-model="scope.value"
                  dense
                  autofocus
                  @keyup.enter="updateGrade(subject, scope)"
                >
                  <template v-slot:append>
                    <q-btn
                      flat
                      icon="edit"
                      @click="updateGrade(subject, scope)"
                    />
                  </template>
                </q-input>
              </q-popup-edit>
            </div>
          </div>
        </div>
        <div class="col-1"></div>

        <!-- More Buttom -->
        <div
          class="col-3"
          v-if="
            !checkStatus(subject) &&
            subject.category !== 'Spezielle Betriebswirtschaftslehre' &&
            subject.category !== 'Specializations' &&
            subject.category !== 'Freies Wahlfach' &&
            subject.category !== 'Free Electives and Internship'
          "
        >
          <div class="row justify-end">
            <q-btn-dropdown
              class="shadow-1"
              style="background-color: #5bbdf4"
              flat
              text-color="white"
              :label="buttonText"
              dense
              padding="sm"
              :size="buttonSize"
            >
              <q-list v-for="button in buttons" :key="button">
                <q-item
                  clickable
                  v-close-popup
                  @click="updateStatus(subject, button)"
                >
                  <q-item-section>
                    <q-item-label>{{ button }}</q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-btn-dropdown>
          </div>
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import { ref } from "vue";

export default {
  props: {
    studyId: {
      type: String,
    },
    subject: {
      type: Object,
    },
    studyObject: {
      type: Object,
    },
    sbwlss: {
      type: Array,
    },
    freeElectivess: {
      type: Array,
    },
    model: {
      type: String,
    },
  },
  setup() {
    return {
      grade: ref(null),
    };
  },
  data() {
    // for understanding purposes --> StudyPlan  --> Subject

    return {
      buttons: ["Doing", "Done", "Undo"],
    };
  },
  computed: {
    fontSize() {
      return this.$q.screen.lt.md
        ? "category-title text-h5"
        : "category-title-small text-h6";
    },
    itemSize() {
      return this.$q.platform.is.desktop
        ? "item shadow-1"
        : "item-small shadow-1";
    },
    buttonSize() {
      if (!this.$q.platform.is.desktop || this.model) {
        return "6px";
      } else {
        return "9px";
      }
    },
    buttonText() {
      return this.$q.platform.is.desktop ? "Action" : "";
    },
    sbwlsGroup() {
      return this.studyObject.study.subjects.filter(
        (subject) =>
          subject.category === "Spezielle Betriebswirtschaftslehre" ||
          subject.category === "Specializations"
      );
    },
  },
  methods: {
    showPath(subject, event) {
      this.$emit("show-path", subject, event);
    },
    /**
     * emitted das angeklickte Subject und den .innerText des gedrückten Buttons
     * @param {typeof<Subject && SubjectStatus>} subject
     * @param {"Doing" | "Done" | "Undo"} button The buttons inner text
     */
    updateStatus(subject, button) {
      let status = button.toLowerCase();

      // remap because status is defined differentky
      if (status == "undo") {
        status = "can-do";
        subject.grade = 0;
      }

      this.$emit("update-status", subject, status);
    },
    /**
     * emitted das angeklickte Subject und den .innerText des gedrückten Buttons
     * @param {typeof<Subject && SubjectStatus>} subject
     * @param {*} scope The scope of the input
     */
    updateGrade(subject, scope) {
      scope.set(this.$emit("update-grade", subject, scope.value));
    },
    /* Checkt ob Subject Status "unavailable" ist --> deaktiviert Aktion Buttons */
    checkStatus(subject) {
      for (let i = 0; i < this.studyObject.subjectStatusEntry.length; i++) {
        let id = this.studyObject.subjectStatusEntry[i].subject_id;
        let status = this.studyObject.subjectStatusEntry[i].status;
        if (subject._id == id && status == "unavailable") {
          return true;
        }
      }
    },
    getSubjectColor() {
      let color = this.subject.color;
      if (this.subject.status == "unavailable" && !this.subject.active) {
        color = "#D9D9D9";
      } else if (this.subject.status == "done" && !this.subject.active) {
        color = "#98F280";
      } else if (this.subject.status == "doing" && !this.subject.active) {
        color = "#FFA03D";
      } else if (this.subject.active) {
        color = "white";
      }
      return color || "#FFFFFF";
    },
    getSubjectActiveColor() {
      let active = this.subject.active;
      let color =
        "repeating-linear-gradient(-45deg, yellow, yellow 10px, transparent 10px, transparent 20px)";

      if (active) {
        return color;
      } else {
        return null;
      }
    },
    showProgressBarSBWL1(id) {
      const sbwlsGroup = this.sbwlsGroup; // Zugriff auf die computed Eigenschaft
      const thirdObject = sbwlsGroup[0]; // Das dritte Objekt in sbwlsGroup
      return thirdObject && id === thirdObject.subject_id;
    },
    showProgressBarSBWL2(id) {
      const sbwlsGroup = this.sbwlsGroup; // Zugriff auf die computed Eigenschaft
      const secondObject = sbwlsGroup[1]; // Das zweite Objekt in sbwlsGroup
      return secondObject && id === secondObject.subject_id;
    },
    showProgressBarSBWL3(id) {
      const sbwlsGroup = this.sbwlsGroup; // Zugriff auf die computed Eigenschaft
      const thirdObject = sbwlsGroup[2]; // Das dritte Objekt in sbwlsGroup
      return thirdObject && id === thirdObject.subject_id;
    },
    calculateProgressSbwl(index) {
      if (this.studyId === "bbe") {
        // If this.sbwlss is empty, return 0
        if (this.sbwlss.length === 0) {
          return 0;
        }

        // Initialize done ECTS
        let doneEcts = 0;

        // Iterate over each sbwl in this.sbwlss
        for (let sbwl of this.sbwlss) {
          // Iterate over each state in sbwl.states
          for (
            let courseIndex = 0;
            courseIndex < sbwl.states.length;
            courseIndex++
          ) {
            if (sbwl.states[courseIndex].status === "done") {
              // Calculate ECTS value based on sbwl_name and courseIndex
              if (sbwl.sbwl_name === "Urban and Regional Economics") {
                doneEcts += courseIndex === 0 ? 4 : courseIndex === 1 ? 8 : 4;
              } else if (sbwl.sbwl_name === "Business Mathematics") {
                doneEcts += courseIndex === 0 ? 8 : 4;
              } else if (
                sbwl.sbwl_name ===
                "Ecological Economics - Economy, Climate Change and Sustainability"
              ) {
                doneEcts += 5;
              } else if (sbwl.sbwl_name === "Economics Fields") {
                doneEcts += courseIndex < 2 ? 8 : 4;
              } else if (
                sbwl.sbwl_name === "European and International Economic Law" ||
                sbwl.sbwl_name === "Mathematical Methods" ||
                sbwl.sbwl_name === "Philosophy: Logic and Ethics"
              ) {
                doneEcts += 5;
              } else if (
                sbwl.sbwl_name ===
                  "Ethics for Management, Organizations, and Society" ||
                sbwl.sbwl_name === "Sustainability Reporting"
              ) {
                doneEcts += courseIndex < 2 ? 6 : 4;
              } else if (
                sbwl.sbwl_name === "Topics in Economic and Social History"
              ) {
                doneEcts += 10;
              } else {
                // Standard logic
                if (sbwl.states.length === 5) {
                  doneEcts += 4;
                } else {
                  doneEcts += courseIndex < 2 ? 6 : 4;
                }
              }
            }
          }
        }

        // Calculate the progress based on done ECTS
        const progress = Math.min(doneEcts / 40, 1); // Cap the progress at 100% (1)

        return progress;
      } else {
        // Überprüfen, ob das Objekt an der angegebenen Indexposition existiert
        if (this.sbwlss[index]) {
          const states = this.sbwlss[index].states;
          const totalEcts = 20; // Maximaler Wert der ECTS-Punkte

          // Summiere die ECTS-Punkte der abgeschlossenen Objekte
          const doneEcts = states
            .filter((obj) => obj.status === "done")
            .reduce((sum, obj) => sum + obj.ects, 0);

          // Berechnen des Fortschritts basierend auf der Anzahl der abgeschlossenen Objekte
          return doneEcts > 0 ? doneEcts / totalEcts : 0;
        }
        // Wenn das Objekt nicht existiert, gib einfach 0 zurück
        return 0;
      }
    },
    getFreeElectivesECTS() {
      const wahlfach = this.studyObject.study.subjects.find(
        (sub) =>
          sub.category == "Freies Wahlfach" ||
          sub.category == "Free Electives and Internship"
      );

      if (wahlfach) {
        const wahlfachId = wahlfach.subject_id;

        const foundWahlfach = this.studyObject.subjects.find(
          (sub) => sub._id == wahlfachId
        );

        return foundWahlfach.ects;
      }
      return 0;
    },
    calculateProgressFreeElectives() {
      const totalECTS = this.getFreeElectivesECTS();
      const completedECTS = this.freeElectivess.reduce(
        (acc, curr) => acc + curr.ects,
        0
      );
      return completedECTS / totalECTS;
    },
    checkSubjectName() {
      const allowedNamesWire = [
        "Marketing",
        "Personal, Führung, Organisation",
        "Finanzwirtschaft",
        "Beschaffung, Logistik, Produktion",
        "Mathematik",
        "Makroökonomie",
      ];
      const allowedNamesVw = [
        "Arbeitsmarktökonomie",
        "Geld und Konjunktur",
        "Industrieökonomie",
        "Institutionelle Ökonomie",
        "Internationale Wirtschaft",
        "Verteilungstheorie und -empirie",
        "Corporate Governance",
        "Makroökonomik der Digitalisierung",
      ];
      if (this.studyId == "wire-23") {
        if (allowedNamesWire.includes(this.subject.name)) {
          return true;
        }
      } else if (this.studyId == "wiso-vwl-23") {
        if (allowedNamesVw.includes(this.subject.name)) {
          return true;
        }
      } else {
        return false;
      }
    },
    getSubjectNameTooltip() {
      if (this.studyId == "wire-23") {
        return this.$t("studyplan.subject_quantity_check_wire23");
      } else if (this.studyId == "wiso-vwl-23") {
        return this.$t("studyplan.subject_quantity_check_vw23");
      } else {
        return "";
      }
    },
    handleDragStart($event, subject) {
      this.$emit("handle-drag-start", $event, subject);
    },
  },
  async mounted() {},
  async updated() {},
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

button {
  font-family: "Poppins", sans-serif;
}

.my-card {
  width: 100%;
  height: 100%;
  max-height: 220px;
  color: $grey-9;
}

.badge {
  background-color: transparent;
  position: absolute;
  top: -20px;
  left: -20px;
  z-index: 2;
}

.banner {
  height: 70%;
  transition: all 300ms ease;
}

.category-title {
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", Poppins, Poppins;
  font-weight: bold;
  word-wrap: break-word;
}

.category-title-small {
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", Poppins, Poppins;
  font-weight: bold;
  word-wrap: break-word;
}

.course-title {
  font-size: min(2.5vw, 16px);
  font-family: Poppins, sans-serif;
}

.items {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 8px;
}

.item {
  background-color: $yellow-2;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 60px;
  min-height: 20px;
  max-width: 80px;
  margin-right: 10px;
  transition: all 300ms ease;
}

.item-small {
  background-color: $yellow-2;
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 40px;
  min-height: 30px;
  max-width: 50px;
  margin-right: 10px;
  transition: all 300ms ease;
}

.upper-text {
  margin-bottom: 0;
  font-size: min(1.5vw, 15px);
  font-weight: bold;
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", Poppins, Poppins;
}

.lower-text {
  margin-bottom: 0;
  font-size: min(1vw, 9px);
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", Poppins, Poppins;
}

@media only screen and (min-width: 1024px) and (max-width: 1439.99px) {
  .my-card {
    height: 100%;
    width: 100%;
    max-height: 220px;
    min-width: 250px;
  }

  .item {
    background-color: $yellow-2;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-height: 32px;
    min-height: 20px;
    max-width: 50px;
    margin-right: 10px;
  }

  .course-title {
    font-size: 12px;
  }

  .upper-text {
    font-size: min(1.5vw, 12px);
  }
}

@media only screen and (min-width: 0px) and (max-width: 599.99px) {
  .upper-text {
    font-size: min(2.5vw, 12px);
  }

  .lower-text {
    font-size: min(1.7vw, 9px);
  }

  .course-title {
    font-size: 13px;
  }
}
</style>
