<template>
<div>
      <q-btn id="addIcon" icon="add" color="blue-4" round size="lg" @click="setSeamless()" @dragenter="setSeamless()">
        <q-badge
        v-if="this.droppedItems.length > 0"
        color="orange"
        floating
        text-color="white"
        :label="this.droppedItems.length"
      />
      <q-tooltip> {{$t('lvplaner.lvcart_lvcart_button_tooltip')}} </q-tooltip>
      </q-btn>
      <q-dialog position="bottom" v-model="internalSeamless" seamless style="z-index: 2000;">
        <div class="shadow-1 scroll q-pa-md" style="height: 450px; width: 100%; margin-bottom: 10px; border-bottom-left-radius: 4px;border-bottom-right-radius: 4px;background-color: rgba(255, 255, 255, 0.95);border: 1px solid #ddd;">
      <q-toolbar>
      <q-btn
        icon="close"
        text-color="blue-7"
        flat
        round
        dense
         v-close-popup
      />
      <q-toolbar-title class="text-center text-blue-7"> {{$t('lvplaner.lvcart_add_lvs')}} </q-toolbar-title>
      </q-toolbar>
      <q-card class="no-shadow">
        <q-card-section class="q-pa-none">
          <div class="text-center text-amber-7">
            LVs: {{ this.droppedItems.length }} ECTS: {{ droppedEctsSum }}
          </div>
        </q-card-section>
        <q-card-section class="row q-col-gutter-sm">
          <div
            class="col-xs-12 col-md-auto"
            v-for="(droppeditem, i) in droppedItems"
            :key="i"
          >
            <div
              class="q-pa-sm rounded-borders shadow-1"
              style="display: inline-block; position: relative"
            >
              <div class="badge">
                <q-btn
                  round
                  color="red-3"
                  icon="close"
                  size="xs"
                  style="opacity: 0.8"
                  @click="removeDroppedItem(i)"
                />
              </div>
              {{ droppeditem.name }} - {{ droppeditem.ects }} ECTS
            </div>
          </div>
        </q-card-section>
        <q-card-actions align="center">
          <q-btn
            :disable="this.droppedItems.length == 0"
            icon-right="navigate_next"
            text-color="white"
            color="blue-7"
            :label="$t('lvplaner.lvcart_go_to_lvplaner')"
            @click="this.$router.push('/addlv')"
          />
          <q-tooltip v-if="this.droppedItems.length == 0"
            > {{$t('lvplaner.lvcart_no_lv_added')}} </q-tooltip
          >
        </q-card-actions>
        <q-card-section>
          <div
            class="dragzone text-justify row items-center justify-center q-pa-sm rounded-borders"
            @dragover.prevent
            @drop="handleDrop($event)"
          >
            {{$t('lvplaner.lvcart_drag')}}
          </div>
        </q-card-section>
      </q-card>
        </div>
    </q-dialog>
    </div>
</template>
<script>
export default {
    props: {
        seamless: {
            type: Boolean
        },
        droppedItems: {
            type: Array
        },
        droppedEctsSum: {
            type: Number
        }
    },
    data() {
        return {
            internalSeamless: this.seamless
        }
    },  
    methods: {
        setSeamless() {
            this.internalSeamless = true;
            this.$emit("setSeamless", this.internalSeamless)
        },
        handleDrop(evt) {
            this.$emit("drop", evt)
        },
        removeDroppedItem(index) {
            this.$emit("removeDroppedItem", index)
        }
    },
    watch: {
        seamless(newValue) {
        // Aktualisiere die lokale Datenvariable, wenn sich die Prop ändert
      this.internalSeamless = newValue;
        },
        internalSeamless(newValue) {
        // Löse das Ereignis aus, wenn sich die lokale Datenvariable ändert
        this.$emit("update:seamless", newValue);
        },
  },

}
</script>
<style scoped>
.dragzone {
  border: dashed lightgrey;
  min-height: 200px;
  transition: all 200ms;
}
.badge {
  background-color: transparent;
  position: absolute;
  top: -10px;
  left: -10px;
  z-index: 2;
}
</style>